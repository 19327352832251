<template>
    <div class="c-cursor js-cursor"></div>
</template>

<script setup>

    const availableStates = ['scroll'];

    onMounted(() => {
        document.addEventListener("mousemove", (event) => {
            const x = event.clientX;
            const y = event.clientY;
            const cursor = document.querySelector('.js-cursor');
            cursor.style.top = y + 'px';
            cursor.style.left = x + 'px';
            cursor.style.transform = `translate(-50%, -50%)`;

            availableStates.forEach(state => {
                if(event.target.closest(`.js-cursor-${state}`)){
                    cursor.classList.add(`c-cursor--${state}`);
                }
                else if(cursor.classList.contains(`c-cursor--${state}`)) {
                    cursor.classList.remove(`c-cursor--${state}`);
                }

                if(state == 'scroll'){
                    const positionRelativeToCentre = event.clientX - window.screen.width / 2;
                    cursor.style.transform = positionRelativeToCentre > 0 ? `translate(-50%, -50%)` : `translate(-50%, -50%) rotate(180deg)`;
                }
            });

        });
    })
</script>
